@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Segoe";
  font-weight: 900;
  font-style: italic;
  src: local("SegoeUIBoldItalic"), url("./fonts/SegoeUIBoldItalic.ttf");
}
@font-face {
  font-family: "Segoe";
  font-weight: 900;
  src: local("SegoeUIBold"), url("./fonts/SegoeUIBold.ttf");
}
@font-face {
  font-family: "Segoe";
  font-weight: 500;
  font-style: italic;
  src: local("SegoeUIItalic"), url("./fonts/SegoeUIItalic.ttf");
}
@font-face {
  font-family: "Segoe";
  font-weight: 500;
  src: local("SegoeUI"), url("./fonts/SegoeUI.ttf");
}

body {
  font-size: 14px;
  margin: 0;
  font-family: "Segoe", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn-primary {
  @apply bg-blue text-white px-7 py-3 rounded-md cursor-pointer font-semibold border-2 border-blue;
}
.btn-primary:disabled {
  @apply bg-gray-400 text-white cursor-default border-gray-400;
}

.btn-secondary {
  @apply bg-transparent text-blue px-7 py-3 rounded-md cursor-pointer font-semibold border-blue border-2;
}

.btn-link {
  @apply text-blue underline;
}
.btn-link:disabled {
  @apply bg-gray-600 cursor-default;
}

.input-primary {
  @apply border bg-white border-gray-300 p-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue border-2 border-gray-200;
}

.centered-page {
  @apply w-screen min-h-screen flex items-center justify-center;
}

.background-img {
  background-image: url("./images/image-2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.input-primary {
  @apply px-4 py-2 rounded-md border border-gray-400;
}

.stat-number-card {
  @apply bg-gray-200 text-blue py-3 px-6 font-bold text-2xl w-max;
}

.stat-number-card-month {
  @apply bg-gray-200 py-3 px-6;
}

.stat-number-card-month .title {
  @apply text-black text-lg font-bold;
}

.stat-number-card-month .key-values {
  @apply grid grid-cols-2;
  grid-template-columns: 4fr 1fr;
}

.stat-number-card-month .key {
  @apply font-bold;
  justify-self: left;
  align-self: center;
}

.stat-number-card-month .value {
  @apply font-bold text-blue text-xl;
  justify-self: right;
}

#india-map-svg path {
  cursor: pointer;
  fill: #bbb;
  transition: 0.2s;
}
.bg-img {
  background-image: url("./images/hero.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  min-height: 100vh;
  background-attachment: fixed;
}

.rdt_TableHead {
  font-weight: bold !important;
}
